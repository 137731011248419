var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-form',{ref:"formRegister",attrs:{"form":_vm.form,"id":"formRegister"}},[_c('a-form-item',[_c('div',{staticClass:"allInputBox"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('user.email.required'),
                  pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
                }
              ],
              validateTrigger: ['change', 'blur']
            }
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('user.email.required'),\n                  pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$/\n                }\n              ],\n              validateTrigger: ['change', 'blur']\n            }\n          ]"}],attrs:{"size":"large","type":"text","placeholder":"请输入邮箱"}})],1)]),_c('a-form-item',[_c('div',{staticClass:"allInputBox"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: true, message: '请输入密码' }, { validator: this.handlePasswordLevel }],
              validateTrigger: ['change', 'blur']
            }
          ]),expression:"[\n            'password',\n            {\n              rules: [{ required: true, message: '请输入密码' }, { validator: this.handlePasswordLevel }],\n              validateTrigger: ['change', 'blur']\n            }\n          ]"}],attrs:{"size":"large","placeholder":"请输入密码"},on:{"click":_vm.handlePasswordInputClick}})],1)]),_c('a-form-item',[_c('div',{staticClass:"allInputBox"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirmPassword',
            {
              rules: [{ required: true, message: '请输入确认密码' }, { validator: this.handlePasswordCheck }],
              validateTrigger: ['change', 'blur']
            }
          ]),expression:"[\n            'confirmPassword',\n            {\n              rules: [{ required: true, message: '请输入确认密码' }, { validator: this.handlePasswordCheck }],\n              validateTrigger: ['change', 'blur']\n            }\n          ]"}],attrs:{"size":"large","placeholder":"请输入确认密码"}})],1)]),_c('a-form-model-item',{staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"allInputBox"},[_c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: ['change', 'blur'] }
            ]),expression:"[\n              'code',\n              { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: ['change', 'blur'] }\n            ]"}],attrs:{"size":"large","placeholder":"请输入验证码"}}),_c('a-button',{staticStyle:{"margin-left":"10px","height":"45px","border-radius":"12px"},attrs:{"size":"large","type":"primary","disabled":_vm.time !== 60},on:{"click":_vm.handleCode}},[_vm._v(_vm._s(_vm.time == 60 ? '获取验证码' : `${_vm.time}s后再次获取`))])],1)])]),_c('a-form-item',{staticStyle:{"padding-bottom":"6vh"}},[_c('div',{staticClass:"btn-box"},[_c('a-button',{staticClass:"register-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.registerBtn,"disabled":_vm.registerBtn},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(_vm._s('确认修改')+" ")]),_c('router-link',{staticClass:"login",attrs:{"to":{ name: 'login' }}},[_vm._v("去登陆")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }